import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl';

import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import SEO from '../components/seo'
import { colors, spacings, breakpointsDown } from '../styling/constants';

import logo from "../images/logo-light.svg"

const GatsbyHeroImage = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &:after {
    background-position: center;
  }
  &:before {
    background-position: center;
  }
`

const BeltLogo = styled.img`
  width: 230px;
  margin-top: 2.5rem;
`

const InfoContainer = styled.div`
  margin: 1rem;
  padding: 4rem 4rem 2.5rem 4rem;
  max-width: 40rem;
  text-align: center;
  color: ${colors.darkGray};
  background-color: ${colors.lightGray};

  @media ${breakpointsDown.tablet} {
    margin: 0;
    padding: 3rem 2rem 1.5rem 2rem;
  }
`

const A = styled.a`
  text-decoration: underline;
`

const Footer = styled.div`
  width: 1rem;
  height: 1rem;
`

const IndexPage = ({ data }) => (
  <>
    <SEO title="Hem" keywords={[`belt`, `sweden`, `belts`]} />
    <GatsbyHeroImage Tag="div" style={{backgroundPosition: ''}} fluid={ data.treesBg.childImageSharp.fluid }>
      <BeltLogo src={logo} alt="Belt logo" />
      <InfoContainer>
        <h1>Snart lanserar vi!</h1>
        <p>Under en längre tid har vi funderat på hur det perfekta skärpet skulle kunna se ut. I början av september 2020 är det dags för oss att visa vad vi kommit fram till — prisvärda och snygga skärp med bra passform för alla.</p>
        <p>Följ oss på Instagram på <A href="https://instagram.com/beltofsweden?igshid=p6mtgzsfe24g">@beltofsweden</A> för att få veta mer!</p>
      </InfoContainer>
      <Footer />
    </GatsbyHeroImage>
  </>
)

export const query = graphql`
  query {
    treesBg: file(relativePath: { eq: "trees.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage

